import React from 'react';
import BlogArticleComponent from '../component/Blog/blogArticleComponent';
import BlogHomeComponent from '../component/Blog/blogHomeComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import BlogImage from '../images/blog_banner.png';
import Layout from '../component/layout';
const Blog = props => {
  const {
    data: {
      wpPage: {
        seo,
        blog: {
          blogCloudsData,
          blogCloudsDescription,
          blogCloudsName,
          blogCloudsText,
          blogCloudsTitle,
          blogCloudsImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogCloudsImage },
            },
          },
        },
        blogBuildingMvpSection: {
          blogBuildingMvpData,
          blogBuildingMvpDescription,
          blogBuildingMvpName,
          blogBuildingMvpText,
          blogBuildingMvpTitle,
          blogBuildingMvpImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogBuildingMvpImage },
            },
          },
        },
        blogEmotionalIntelligenceSection: {
          blogEmotionalIntelligenceData,
          blogEmotionalIntelligenceDescription,
          blogEmotionalIntelligenceName,
          blogEmotionalIntelligenceText,
          blogEmotionalIntelligenceTitle,
          blogEmotionalIntelligenceImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: blogEmotionalIntelligenceImage,
              },
            },
          },
        },
        blogKickOffAProjectSection: {
          blogKickOffAProjectData,
          blogKickOffAProjectDescription,
          blogKickOffAProjectName,
          blogKickOffAProjectText,
          blogKickOffAProjectTitle,
          blogKickOffAProjectImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogKickOffAProjectImage },
            },
          },
        },
        blogMvpSection: {
          blogMvpButton,
          blogMvpData,
          blogMvpDescription,
          blogMvpName,
          blogMvpText,
          blogMvpTitle,
          blogMvpImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogMvpImage },
            },
          },
        },
        blogSkillsSection: {
          blogSkillsData,
          blogSkillsDescription,
          blogSkillsName,
          blogSkillsText,
          blogSkillsTitle,
          blogSkillsImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogSkillsImage },
            },
          },
        },
        blogUxSection: {
          blogUxData,
          blogUxDescription,
          blogUxName,
          blogUxText,
          blogUxTitle,
          blogUxImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: blogUxImage },
            },
          },
        },
        homePageHeaderSection: { homeHeaderTitle, homeHeaderDescription },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout seo={seo} title={title}>
      <BlogHomeComponent
        blogTitle={homeHeaderTitle}
        blogDescription={homeHeaderDescription}
        ourWorkImage={BlogImage}
      />
      <BlogArticleComponent
        blogArticleLink='/building-your-mvp-a-step-by-step-guide'
        blogArticleTitle={blogBuildingMvpTitle}
        blogArticleBy='by'
        blogArticleByWho={blogBuildingMvpName}
        blogArtivleOn={blogBuildingMvpText}
        blogArticleDate={blogBuildingMvpData}
        blogArticleDescription={blogBuildingMvpDescription}
        blogArticleButton={blogMvpButton}
        blogImage={blogBuildingMvpImage}
      />
      <BlogArticleComponent
        blogArticleLink='/the-battle-of-the-clouds-aws-vs.-azure'
        blogArticleTitle={blogCloudsTitle}
        blogArticleBy='by'
        blogArticleByWho={blogCloudsName}
        blogArtivleOn={blogCloudsText}
        blogArticleDate={blogCloudsData}
        blogArticleDescription={blogCloudsDescription}
        blogArticleButton={blogMvpButton}
        blogImage={blogCloudsImage}
      />
      <BlogArticleComponent
        blogArticleLink='/how-to-kick-off-a-project'
        blogArticleTitle={blogKickOffAProjectTitle}
        blogArticleBy='by'
        blogArticleByWho={blogKickOffAProjectName}
        blogArtivleOn={blogKickOffAProjectText}
        blogArticleDate={blogKickOffAProjectData}
        blogArticleDescription={blogKickOffAProjectDescription}
        blogArticleButton={blogMvpButton}
        blogImage={blogKickOffAProjectImage}
      />
      <BlogArticleComponent
        blogArticleLink='/hiring-for-the-emotional-intelligence'
        blogArticleTitle={blogEmotionalIntelligenceTitle}
        blogArticleBy='by'
        blogArticleByWho={blogEmotionalIntelligenceName}
        blogArtivleOn={blogEmotionalIntelligenceText}
        blogArticleDate={blogEmotionalIntelligenceData}
        blogArticleDescription={blogEmotionalIntelligenceDescription}
        blogArticleButton={blogMvpButton}
        blogImage={blogEmotionalIntelligenceImage}
      />
      <BlogArticleComponent
        blogArticleLink='/choosing-the-right-tech-slack-for-your-mvp'
        blogArticleTitle={blogMvpTitle}
        blogArticleBy='by'
        blogArticleByWho={blogMvpName}
        blogArtivleOn={blogMvpText}
        blogArticleDate={blogMvpData}
        blogArticleDescription={blogMvpDescription}
        blogArticleButton={blogMvpButton}
        blogImage={blogMvpImage}
      />
      <BlogArticleComponent
        blogArticleLink='/the-ux-best-practices-that-will-make-you-a-great-designer'
        blogArticleTitle={blogUxTitle}
        blogArticleBy='by'
        blogArticleByWho={blogUxName}
        blogArtivleOn={blogUxText}
        blogArticleDate={blogUxData}
        blogArticleDescription={blogUxDescription}
        blogArticleButton={blogMvpButton}
        blogImage={blogUxImage}
      />
      <BlogArticleComponent
        blogArticleLink='/the-5-core-skills-the-future-workforce-needs-to-have'
        blogArticleTitle={blogSkillsTitle}
        blogArticleBy='by'
        blogArticleByWho={blogSkillsName}
        blogArtivleOn={blogSkillsText}
        blogArticleDate={blogSkillsData}
        blogArticleDescription={blogSkillsDescription}
        blogArticleButton={blogMvpButton}
        isEnd={true}
        blogArticleLoadButton='Load more'
        blogImage={blogSkillsImage}
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "blog-page" }) {
      seo {
        metaDesc
        title
      }
      blog {
        blogCloudsData
        blogCloudsDescription
        blogCloudsName
        blogCloudsText
        blogCloudsTitle
        blogCloudsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogBuildingMvpSection {
        blogBuildingMvpData
        blogBuildingMvpDescription
        blogBuildingMvpName
        blogBuildingMvpText
        blogBuildingMvpTitle
        blogBuildingMvpImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogEmotionalIntelligenceSection {
        blogEmotionalIntelligenceData
        blogEmotionalIntelligenceDescription
        blogEmotionalIntelligenceName
        blogEmotionalIntelligenceText
        blogEmotionalIntelligenceTitle
        blogEmotionalIntelligenceImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogKickOffAProjectSection {
        blogKickOffAProjectData
        blogKickOffAProjectDescription
        blogKickOffAProjectName
        blogKickOffAProjectText
        blogKickOffAProjectTitle
        blogKickOffAProjectImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogMvpSection {
        blogMvpButton
        blogMvpData
        blogMvpDescription
        blogMvpName
        blogMvpText
        blogMvpTitle
        blogMvpImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogSkillsSection {
        blogSkillsData
        blogSkillsDescription
        blogSkillsName
        blogSkillsText
        blogSkillsTitle
        blogSkillsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogUxSection {
        blogUxData
        blogUxDescription
        blogUxName
        blogUxText
        blogUxTitle
        blogUxImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default Blog;
