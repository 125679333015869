import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Link } from 'gatsby';
const BlogArticleComponent = props => {
  const {
    blogArticleTitle,
    blogArticleBy,
    blogArticleByWho,
    blogArtivleOn,
    blogArticleDate,
    blogArticleDescription,
    blogArticleButton,
    isEnd,
    blogImage,
    blogArticleLink,
  } = props;
  return (
    <div
      className={`blog-article-section ${
        isEnd ? 'blog-article-section-text' : ''
      }`}
    >
      <div className='full-container'>
        <div className='row'>
          <div className='col-md-6'>
            <GatsbyImage
              alt='blogImage'
              image={blogImage}
              className='blog-article-section-image'
            />
          </div>
          <div className='col-md-6 blog-article-section-col'>
            <h2 className='blog-article-section-title'>{blogArticleTitle}</h2>
            <p className='blog-article-section-subtitle'>
              {blogArticleBy} &nbsp;
              <span className='blog-article-section-subtitle-span'>
                {blogArticleByWho} &nbsp;
              </span>
              {blogArtivleOn} &nbsp;
              <span className='blog-article-section-subtitle-span'>
                {blogArticleDate}
              </span>
            </p>
            <p className='blog-article-section-description'>
              {blogArticleDescription?.replaceAll('Motomtech', 'UpTech Sh.p.k')}
            </p>
            <Link className='blog-article-section-button' to={blogArticleLink}>
              {blogArticleButton}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogArticleComponent;
